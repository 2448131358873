import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Home from "./components/home";
import Resume from "./components/Resume";
import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";

import "./App.css";

function App() {
    return (
        <React.Fragment>
            <CssBaseline/>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </Router>
        </React.Fragment>
    );
}

export default App;
