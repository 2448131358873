import React from "react";
import { Particles as ReactParticles } from "react-particles";
import { makeStyles } from "@material-ui/core/styles";
import {loadFull} from "tsparticles";

const useStyles = makeStyles(() => ({
  particlesCanvas: {
    position: "fixed",
    opacity: "0.3"
  },
}));

const Particles = () => {
  const classes = useStyles();

  // noinspection JSValidateTypes
  return (
    <ReactParticles
      id='main-particles'
      init={async (main) => {
        await loadFull(main)
      }}
      canvasClassName={classes.particlesCanvas}
      options={{
        fullScreen: {
          enable: true,
          zIndex: 1
        },
        particles: {
          number: {
            value: 45,
            density: {
              enable: false,
              value_area: 800,
            },
          },
          shape: {
            type: "circle",
          },
          size: {
            value: 8,
            random: true,
            anim: {
              enable: false,
              speed: 6,
              size_min: 0,
              sync: true,
            },
          },
          opacity: {
            value: 0.5,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          lineLinked: {
            enable: true,
            distance: 250,
            color: "#ffffff",
            opacity: 0.4,
            width: 2
          },
          move: {
            enable: true,
            speed: 1.33,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "bounce",
            attract: {
              enable: true,
              distance: {
                min: 10,
                max: 150
              }
            },
            collisions: false
          },

        },
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: ["grab"]
            },
            onclick: {
              enable: false,
              mode: "bubble"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 200,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 200,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
            },
            repulse: {
              distance: 200
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 2
            }
          }
        },
      }}
    />
  );
};

export default Particles;
